<template>
  <v-card class="mx-auto pa-3" max-width="700">
    <v-card>
      <v-img :src="bannerLink" max-height="500"></v-img>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>Poštovani roditelji,</v-card-text>

      <v-card-text> {{ tekstEventa }}</v-card-text>

      <v-card-text> Vidimo se! </v-card-text>
    </v-card>
    <v-text-field label="Ime i prezime djeteta" v-model="polaznik" class="mt-5" v-if="!dispNemaTermina"></v-text-field>
    <v-radio-group v-model="selectedTermin" label="Termini:" v-if="!dispNemaTermina">
      <v-radio
        v-for="t in termini"
        :key="t.polaznici.length + t.naziv"
        :value="t"
        :label="`${t.centar} - ${t.datum} , slobodnih mjesta: ${t.max - t.polaznici.length}`"
        :disabled="brojSlobodnih(t)"
      >
      </v-radio>
    </v-radio-group>
    <v-btn @click="dodajPolaznika()" class="primary" v-if="dispSubmit && !dispNemaTermina"> <v-icon>mdi-account</v-icon>Prijava na radionicu</v-btn>
    <v-alert class="my-2" type="error" v-if="dispError.length > 0" dismissible :key="dispError.length">{{ dispError }}</v-alert>
    <v-alert class="my-2" type="success" v-if="!dispSubmit">Uspješno ste se prijavili za radionicu</v-alert>
    <v-alert class="my-2" type="warning" v-if="dispNemaTermina && dispSubmit">Na žalost više nema slobodnih termina za radionice</v-alert>
  </v-card>
</template>

<script>
// @ is an alias to /src
import db from "../firebaseInit.js";

//import { doc, onSnapshot, getDoc } from "firebase/firestore";
import firebase from "firebase";

export default {
  data: () => ({
    loaded: false,
    polaznik: "",
    selectedTermin: {},
    termini: [],
    dogadjaj: "",
    dispError: "",
    bannerLink: "",
    title: "",
    tekstEventa: "",
    dispSubmit: true,
    dispNemaTermina: false,
  }),
  methods: {
    async dodajPolaznika() {
      //console.log(typeof this.selectedTermin.id);

      if (this.polaznik.length < 5) {
        this.dispError = "Molimo unesite polaznika/polaznicu";
      } else {
        if (typeof this.selectedTermin.id === "undefined") {
          this.dispError = "Molimo izaberite termin";
        } else {
          if (!this.brojSlobodnih(this.selectedTermin)) {
            const ref = db.collection("eventList").doc(this.dogadjaj).collection("termini").doc(this.selectedTermin.id);
            await ref.update({
              polaznici: firebase.firestore.FieldValue.arrayUnion(this.polaznik),
            });
            this.dispSubmit = false;
            this.dispError = "";
          } else {
            this.dispError = "Navedeni termin je popunjen, molimo izaberite drugi termin!";
          }
        }
      }
    },
    brojSlobodnih(t) {
      if (t.polaznici === undefined) return false;
      //console.log("polaznici length: " + t.polaznici.length);
      var b = t.max - t.polaznici.length;
      //console.log("b: " + b);
      //console.log(typeof b);
      if (b <= 0) {
        //console.log("r t");
        return true;
      } else {
        //console.log("r f");
        return false;
      }
    },
  },
  watch: {
    termini: {
      handler(noviTermini) {
        //console.log(noviTermini);
        var postojiTermin = false;
        for (let t of noviTermini) {
          if (!this.brojSlobodnih(t)) {
            //console.log("postoji Termin ");

            postojiTermin = true;
          } else {
            //console.log("ne postoji termin");
          }
        }
        if (!postojiTermin) {
          this.dispNemaTermina = true;
        }
      },
      deep: true,
    },
  },
  async created() {
    //console.log("mounted??<<");
    //var docRef = db.collection("eventList").doc("testTermin1");
    var ref = db.collection("activeEvent").doc("eventTitle");
    var rData = await ref.get();
    this.dogadjaj = rData.data().eventName;

    ref = db.collection("eventList").doc(this.dogadjaj).collection("termini");
    //works

    // ref.get().then((qS) => {
    //   qS.forEach((d) => {
    //     this.termini.push({ id: d.id, ...d.data() });
    //   });
    //   //      console.log(this.termini);
    // });

    ref.onSnapshot((qS) => {
      var newTermini = [];
      qS.forEach((d) => {
        newTermini.push({ id: d.id, ...d.data() });
      });
      this.termini = newTermini;
    });

    const infoRef = db.collection("eventList").doc(this.dogadjaj);

    const infoDoc = await infoRef.get();
    //console.log(infoDoc.data());
    this.bannerLink = infoDoc.data().imglink;
    this.title = infoDoc.data().title;
    this.tekstEventa = infoDoc.data().text;

    // //const ref = db.collection("eventList").doc(this.dogadjaj).collection("termini").doc("Sigecica");

    // const doc = await ref.get();
    // console.log(doc.data());
    // this.termini.push(doc.data());

    // const observerS = ref.onSnapshot((docS) => {
    //   console.log(docS.data());
    //   this.termini = { ["sigecica"]: docS.data() };
    // });

    this.loaded = true;
  },
};
</script>
